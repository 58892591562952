// main slide
//------------------------------------------------------------

var swiper = new Swiper('.swiper-container', {
    pagination: '.swiper-pagination',
    paginationClickable: true,
    speed: 1800,
    loop: true,
    autoplay: 4500
});

// smooth scroll
//------------------------------------------------------------

jQuery(function() {
    // スクロールのオフセット値
    var offsetY = -($('.top-navi').height());
    console.log(offsetY);
    // スクロールにかかる時間
    var time = 800;

    // ページ内リンクのみを取得
    $('.alnk').click(function() {
        // 移動先となる要素を取得
        var target = $(this.hash);
        if (!target.length) return;
        // 移動先となる値
        var targetY = target.offset().top + offsetY;
        console.log(targetY);
        // スクロールアニメーション
        $('html,body').animate({
            scrollTop: targetY
        }, time, 'swing');
        // ハッシュ書き換えとく
        window.history.pushState(null, null, this.hash);
        // デフォルトの処理はキャンセル
        return false;
    });
});


// vertical middle
//------------------------------------------------------------

jQuery(window).on("load resize orientationchange", function() {

    $(function() {
        // 基準になる要素の高さを取得
        var guideH = $("#about img").height();
        // 高さを中央揃えにしたい要素の高さを取得
        var middle = $("#about .vertical-middle").height();
        // 基準の高さ-中央指定の高さ / 2
        var paddingTop = ((guideH - middle) / 2);

        // 基準の高さより指定要素の高さが小さい時だけ実行
        if (guideH > middle) {
            $("#about .vertical-middle").css('padding-top', paddingTop + 'px');
        }
    });

});

// top navi
//------------------------------------------------------------
jQuery(function($) {

    // 追従メニューの要素を指定
    var nav = $('.top-navi');
    // 初期固定座標位置を取得
    var offset = nav.offset();

    // スクロール時に実行
    $(window).scroll(function() {
        if ($(window).scrollTop() > offset.top) {
            // ターゲットの座標より下に来た時に実行
            nav.addClass('fixed');
            nav.addClass('top-navi-scrollbg');
        } else {
            // それ以外
            nav.removeClass('fixed');
            nav.removeClass('top-navi-scrollbg');
        }
    });

    function fixednavi(target, addClassaAction) {
        // 追従メニューの要素を指定
        var targetNav = $(target);
        // 初期固定座標位置を取得
        var targetOffset = nav.offset();
        // スクロール時に実行
        $(window).scroll(function() {
            if ($(window).scrollTop() > targetOffset.top) {
                // ターゲットの座標より下に来た時に実行
                targetNav.addClass('fixed');
                addClassaAction
            } else {
                // それ以外
                targetNav.removeClass('fixed');
            }
        });
    }
    fixednavi(".title-bar", "");
    fixednavi(".sp-topnavi", $(".sp-topnavi").css("top", $('.title-bar').outerHeight() - 1));
});

// page top
//------------------------------------------------------------

jQuery(function() {
    var topBtn = $('#page-top');
    topBtn.hide();
    //スクロールが100に達したらボタン表示
    $(window).scroll(function() {
        if ($(this).scrollTop() > 70) {
            topBtn.fadeIn().slow();
        } else {
            topBtn.fadeOut().slow();
        }
    });
    //スクロールしてトップ
    topBtn.click(function() {
        $('body,html').animate({
            scrollTop: 0
        }, 800);
        return false;
    });
});
